<template>
	<Transition name="slide-fade">
		<div
			v-if="loaded"
			class="fixed top-8 right-8 bg-white shadow rounded flex items-center justify-between gap-6 z-[2500]">
			<div class="flex items-center">
				<div
					class="p-4 mr-4 rounded md:rounded-tr-none md:rounded-br-none border-r border-gray-200"
					:class="[iconClass]">
					<svg
						v-if="text === 'Danger'"
						class="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1.2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
					<svg
						v-else-if="text === 'Success'"
						class="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1.2"
							d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
					<svg
						v-else
						class="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1.2"
							d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</div>
				<div class="h-1 w-1 bg-gray-300 rounded-full mr-2 hidden xl:block"></div>
				<p
					class="text-sm lg:text-base text-gray-600 lg:pt-1 xl:pt-0 sm:mb-0 mb-2 text-center sm:text-left max-w-lg">
					{{ store.getters.getAlert.message }}
				</p>
			</div>
			<div class="flex xl:items-center lg:items-center sm:justify-end justify-center pr-4 gap-4">
				<span
					class="text-sm cursor-pointer hover:underline font-medium"
					:class="[textClass]"
					@click.prevent="closeAlert()">
					Dismiss
				</span>
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const loaded = ref(false)

onMounted(() => {
	setTimeout(() => {
		loaded.value = true
	}, 0)
})

const iconClass = computed(() => {
	const { type } = store.getters.getAlert

	switch (type) {
		case 'primary':
			return `bg-primary text-white`
		case 'success':
			return `bg-success text-white`
		case `warning`:
			return `bg-warning text-white`
		case `danger`:
			return `bg-danger text-white`
		case `dark`:
			return `bg-dark text-white`
		default:
			return `bg-white text-gray-500`
	}
})

const textClass = computed(() => {
	const { type } = store.getters.getAlert

	if (type) {
		switch (type) {
			case 'primary':
				return `text-blue-500 hover:text-blue-600`
			case 'success':
				return `text-success hover:text-green-600`
			case `warning`:
				return `text-warning hover:text-yellow-600`
			case `danger`:
				return `text-danger hover:text-red-600`
			case `dark`:
				return `text-dark hover:text-gray-900`
			default:
				return `text-gray-500 hover:text-gray-600`
		}
	}
	return ``
})

const text = computed(() => {
	const { type } = store.getters.getAlert

	if (type) {
		switch (type) {
			case 'success':
				return `Success`
			case `warning`:
				return `Warning`
			case `danger`:
				return `Danger`
			default:
				return `Information`
		}
	}
	return `Information`
})

function closeAlert() {
	store.dispatch('hideAlert')
}
</script>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(0.87, 0.03, 0.05, 0.99);
}

.slide-fade-leave-to,
.slide-fade-enter {
	transform: translateX(-20px);
	opacity: 0;
}
</style>
