<template>
	<div class="bg-white">
		<div class="border-b border-gray-200 relative">
			<div class="absolute flex items-center px-6 top-10">
				<svg
					v-if="!getSideMenuOpen && sideNavRoutes.includes(route.matched[0].name)"
					@click.prevent="store.commit('setSideMenuOpen', !getSideMenuOpen)"
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6 text-gray-150 cursor-pointer md:hidden lg:flex"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
				</svg>
				<svg
					v-else-if="sideNavRoutes.includes(route.matched[0].name)"
					@click.prevent="store.commit('setSideMenuOpen', !getSideMenuOpen)"
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6 text-gray-150 cursor-pointer md:hidden lg:flex"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</div>

			<div
				class="h-full align-top 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg lg:py-6 lg:px-8 py-4 mx-auto w-full flex items-center lg:justify-between relative">
				<div class="flex items-center gap-12 w-10 lg:w-full justify-between lg:justify-start">
					<div v-if="tenant" class="cursor-pointer md:block hidden">
						<a href="/">
							<img
								:src="
									route.path.includes('/showcase/stream')
										? 'https://imagedelivery.net/zUHvQVaonKX8fuaOjIvxsg/f95240c8-18aa-4e48-311a-931a19de4900/public'
										: tenant.branding?.logo
								"
								class="max-h-16 cursor-pointer"
								:alt="tenant.branding?.logoName" />
						</a>
					</div>
				</div>
				<div class="flex gap-6">
					<div class="flex items-center gap-2 lg:gap-6">
						<a
							v-for="(item, index) of navItems"
							:key="index"
							class="cursor-pointer text-lg flex lg:gap-2 items-center group"
							:href="item.href"
							:target="item.target">
							<div v-if="item.icon" v-html="item.icon"></div>
							<span class="text-gray-400 font-light hidden lg:block group-hover:text-blue-600">
								{{ item.name }}
							</span>
						</a>
						<n-popover
							placement="bottom"
							trigger="hover"
							v-if="tenant?.id === 'purebookkeeping-5vk48'">
							<template #trigger>
								<a href="https://pureworkflow.io/" target="_blank">
									<svg
										class="w-6 h-6 text-gray-400 hover:text-blue-600 cursor-pointer"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.2"
											d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
									</svg>
								</a>
							</template>
							<div class="large-text">Visit Pure Workflow</div>
						</n-popover>
						<div
							v-if="user?.role?.administrator || user?.role?.collaborator"
							class="cursor-pointer flex gap-2 items-center relative"
							@mouseover=";(openMenu = true), (profileMenu = false)">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6 text-gray-400 hover:text-blue-600"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
						<div
							@mouseleave="openMenu = false"
							class="absolute right-24 bg-white flex flex-col top-20 rounded-md shadow-xl z-50 w-52"
							v-if="openMenu">
							<div
								class="text-center border-b border-gray-100 py-2 bg-blue-600 text-white text-base">
								Admin Menu
							</div>
							<n-menu :options="catalogue" accordion />
						</div>
						<a
							@mouseover=";(profileMenu = true), (openMenu = false)"
							class="cursor-pointer"
							v-if="user"
							href="/user/profile">
							<div class="flex items-center gap-4">
								<div
									v-if="(user?.firstName && user?.lastName) || user?.profileImage"
									class="rounded-full bg-yellow-500 text-white w-12 h-12 flex items-center justify-center text-xs">
									<img
										class="rounded-full w-12 h-12"
										v-if="user?.profileImage"
										:src="user?.profileImage" />
									<div v-else>
										{{ user?.firstName?.charAt(0) + user?.lastName?.charAt(0) }}
									</div>
								</div>
								<p v-else>
									<svg
										class="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
									</svg>
								</p>
							</div>
						</a>
						<div v-else>
							<n-button @click="showModal = !showModal" size="medium" type="primary" class="mt-1">
								Login
							</n-button>
						</div>
						<div
							@mouseleave="profileMenu = false"
							class="absolute right-10 bg-white flex flex-col top-20 rounded-md shadow-xl z-50"
							v-if="profileMenu">
							<n-menu :options="userNav" accordion />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, h, computed, watch, inject } from 'vue'
import { NMenu, NPopover, NButton } from 'naive-ui'
import { useRoute, RouterLink } from 'vue-router'
import { useStore } from 'vuex'
import { showDanger } from '@utils/AlertService'
import { auth } from '@root/firebaseDatabase'
import { AUTH_ERROR_CODES } from '@root/src/constants/Default'

const store = useStore()
const route = useRoute()
const profileMenu = ref(false)

const user = computed(() => {
	return store.state?.userData?.data
})

const tenant = inject('tenant')

const getSideMenuOpen = computed(() => {
	return store.getters.getSideMenuOpen
})

const openMenu = ref(false)

const logout = async () => {
	await auth
		.signOut()
		.then(() => {
			store.dispatch('updateCurrentUser', undefined)
			store.commit('setLoginRedirect', undefined)
			store.dispatch('updateViewAs', undefined)
			store.dispatch('updateUser', undefined)
			store.dispatch('updateSideMenuView', undefined)
			window.open('/login', '_self')
		})
		.catch((error) => {
			console.error('🚀 ~ file: TopNav.vue ~ line 196 ~ handleLogout ~ error', error)
			showDanger(AUTH_ERROR_CODES[error.code] || error.message, 5000)
		})
}

const selectedUserNav = (e) => {
	switch (e) {
		case 'profile':
			window.open('/user/profile', '_self')
			break
		case 'company':
			window.open('/user/company', '_self')
			break
		case 'favorites':
			window.open('/user/favorites', '_self')
			break
		case 'studio':
			window.open('/user/studio', '_self')
			break
		case 'logout':
			logout()
			break
		default:
			break
	}
}

const sideNavRoutes = ['Settings', 'Edit Event', 'Events', 'Profile']

const routingLink = (label, href) => {
	return () =>
		h(RouterLink, { to: href, class: 'font-light text-base pr-4' }, { default: () => `${label}` })
}

const catalogue = [
	{
		label: routingLink('Learning', '/admin/learning'),
		key: 'learning',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />`
			})
	}
]
if (tenant.value.id !== 'communityfinancials-hl7z5') {
	catalogue.push(
		{
			label: routingLink('Events', '/admin/events'),
			key: 'events',
			icon: () =>
				h('svg', {
					fill: 'none',
					stroke: 'currentColor',
					class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
					'aria-hidden': true,
					viewBox: '0 0 24 24',
					'stroke-width': '1.3',
					innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />`
				}),
			children: [
				{
					label: routingLink('Webinars', '/admin/webinars'),
					key: 'webinars',
					icon: () =>
						h('svg', {
							fill: 'none',
							stroke: 'currentColor',
							class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
							'aria-hidden': true,
							viewBox: '0 0 24 24',
							'stroke-width': '1.3',
							innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />`
						})
				},
				{
					label: routingLink('Recordings', '/admin/recordings'),
					key: 'recordings',
					href: '/admin/recordings',
					icon: () =>
						h('svg', {
							fill: 'none',
							stroke: 'currentColor',
							class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
							'aria-hidden': true,
							viewBox: '0 0 24 24',
							'stroke-width': '1.3',
							innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />`
						})
				}
			]
		},
		{
			label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Community' }),
			// label: routingLink('Community', '/admin/community'),
			key: 'community',
			icon: () =>
				h('svg', {
					fill: 'none',
					stroke: 'currentColor',
					class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
					'aria-hidden': true,
					viewBox: '0 0 24 24',
					'stroke-width': '1.3',
					innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>`
				})
		}
	)
}
catalogue.push(
	{
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Tools' }),
		// label: routingLink('Tools', '/admin/tools'),
		key: 'tools',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"></path>`
			}),
		children: [
			{
				label: routingLink('Pages', '/admin/pages'),
				key: 'pages',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>`
					})
			},
			{
				label: routingLink('Templates', '/admin/images'),
				key: 'images',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"></path>`
					})
			},
			{
				label: routingLink('Forms', '/admin/forms'),
				key: 'forms',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />`
					})
			},
			{
				label: routingLink('Assessments', '/admin/assessments'),
				key: 'assessments',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />`
					})
			},
			{
				label: routingLink('Resources', '/admin/resources'),
				key: 'resources',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />`
					})
			},
			{
				label: routingLink('Notifications', '/admin/notifications'),
				key: 'notifications',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" data-v-219a5a68=""></path>`
					})
			},
			{
				label: routingLink('FAQs', '/admin/faqs'),
				key: 'faqs',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />`
					})
			},
			{
				label: routingLink('Analytics', '/admin/analytics'),
				key: 'analytics',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"></path>`
					})
			}
		]
	},
	{
		label: routingLink('Users', '/admin/users'),
		key: 'accounts',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />`
			}),
		children: [
			{
				label: routingLink('Access', '/admin/access'),
				key: 'access',
				href: '/admin/access',
				icon: () =>
					h('svg', {
						fill: 'none',
						stroke: 'currentColor',
						class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
						'aria-hidden': true,
						viewBox: '0 0 24 24',
						'stroke-width': '1.3',
						innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />`
					})
			}
		]
	}
)

if (tenant.value.id !== 'communityfinancials-hl7z5') {
	catalogue[4].children.push({
		label: routingLink('Companies', '/admin/companies'),
		key: 'Companies',
		href: '/admin/companies',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />`
			})
	})
}

catalogue.push({
	label: routingLink('Settings', '/admin/settings'),
	key: 'settings',
	href: '/admin/settings/',
	icon: () =>
		h('svg', {
			fill: 'none',
			stroke: 'currentColor',
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			'aria-hidden': true,
			viewBox: '0 0 24 24',
			'stroke-width': '1.3',
			innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />`
		})
})

const navItems = [
	{
		name: 'Hub',
		href: '/',
		icon: `<svg class="w-6 h-6 text-gray-400 group-hover:text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>`
	},
	{
		name: 'Learning',
		href: '/learning/',
		icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-400 group-hover:text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.3"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" /></svg>`
	}
]
if (tenant.value.id !== 'communityfinancials-hl7z5') {
	navItems.push(
		{
			name: 'Events',
			href: '/events/',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-400 group-hover:text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.3"><path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>`
		},
		{
			name: 'Community',
			href: tenant?.community,
			target: '_blank',
			icon: `<svg class="w-6 h-6 text-gray-400 group-hover:text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>`
		}
	)
}
const userNav = [
	{
		onClick: () => selectedUserNav('profile'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Profile' }),
		key: 'profile',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path>`
			})
	},
	{
		onClick: () => selectedUserNav('studio'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Studio' }),
		key: 'studio',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"></path>`
			})
	}
]

userNav.push({
	onClick: () => selectedUserNav('favorites'),
	label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Favorites' }),
	key: 'favorites',
	icon: () =>
		h('svg', {
			fill: 'none',
			stroke: 'currentColor',
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			'aria-hidden': true,
			viewBox: '0 0 24 24',
			'stroke-width': '1.3',
			innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>`
		})
})

if (tenant.value.id !== 'communityfinancials-hl7z5') {
	userNav.push({
		onClick: () => selectedUserNav('company'),
		label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Company' }),
		key: 'company',
		icon: () =>
			h('svg', {
				fill: 'none',
				stroke: 'currentColor',
				class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
				'aria-hidden': true,
				viewBox: '0 0 24 24',
				'stroke-width': '1.3',
				innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />`
			})
	})
}
userNav.push({
	onClick: () => selectedUserNav('logout'),
	label: () => h('span', { class: 'font-light text-base' }, { default: () => 'Logout' }),
	key: 'logout',
	icon: () =>
		h('svg', {
			fill: 'none',
			stroke: 'currentColor',
			class: 'w-6 h-6 text-gray-400 group-hover:text-blue-600',
			'aria-hidden': true,
			viewBox: '0 0 24 24',
			'stroke-width': '1.3',
			innerHTML: `<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"></path>`
		})
})

const checkSideNav = () => {
	if (sideNavRoutes.includes(route.matched[0].name)) {
		store.commit('setHideNav', false)
	} else {
		store.commit('setHideNav', true)
	}
}

onMounted(() => {
	checkSideNav()
})

watch(
	() => route.name,
	(value) => {
		checkSideNav(value)
	}
)
</script>
<style scoped>
:deep(i.n-base-icon.n-menu-item-content__arrow) {
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
