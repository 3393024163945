import store from '../store'

const showMessage = (message, duration, type) => {
	store.dispatch('showAlert', { message, duration, type })
}

export const showSuccess = (message, duration) => {
	showMessage(message, duration || 2000, 'success')
}

export const showWarning = (message, duration) => {
	showMessage(message, duration || 2000, 'warning')
}

export const showDanger = (message, duration) => {
	showMessage(message, duration || 2000, 'danger')
}

export const showAlert = (message, duration) => {
	showMessage(message, duration || 2000, 'primary')
}

export const showDark = (message, duration) => {
	showMessage(message, duration || 2000, 'dark')
}
