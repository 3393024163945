export const AUTH_ERROR_CODES = {
	'auth/claims-too-large':
		'The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.',
	'auth/email-already-exists':
		'The provided email is already in use by an existing user. Each user must have a unique email.',
	'auth/id-token-expired': 'The provided Firebase ID token is expired.',
	'auth/id-token-revoked': 'The Firebase ID token has been revoked.',
	'auth/insufficient-permission':
		'The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.',
	'auth/internal-error':
		'The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.',
	'auth/invalid-argument':
		'An invalid argument was provided to an Authentication method. The error message should contain additional information.',
	'auth/invalid-claims':
		'The custom claim attributes provided to setCustomUserClaims() are invalid.',
	'auth/invalid-continue-uri': 'The continue URL must be a valid URL string.',
	'auth/invalid-creation-time': 'The creation time must be a valid UTC date string.',
	'auth/invalid-credential':
		'The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.',
	'auth/invalid-disabled-field':
		'The provided value for the disabled user property is invalid. It must be a boolean.',
	'auth/invalid-display-name':
		'The provided value for the displayName user property is invalid. It must be a non-empty string.',
	'auth/invalid-dynamic-link-domain':
		'The provided dynamic link domain is not configured or authorized for the current project.',
	'auth/invalid-email':
		'The provided value for the email user property is invalid. It must be a string email address.',
	'auth/invalid-email-verified':
		'The provided value for the emailVerified user property is invalid. It must be a boolean.',
	'auth/invalid-hash-algorithm':
		'The hash algorithm must match one of the strings in the list of supported algorithms.',
	'auth/invalid-hash-block-size': 'The hash block size must be a valid number.',
	'auth/invalid-hash-derived-key-length': 'The hash derived key length must be a valid number.',
	'auth/invalid-hash-key': 'The hash key must a valid byte buffer.',
	'auth/invalid-hash-memory-cost': 'The hash memory cost must be a valid number.',
	'auth/invalid-hash-parallelization': 'The hash parallelization must be a valid number.',
	'auth/invalid-hash-rounds': 'The hash rounds must be a valid number.',
	'auth/invalid-hash-salt-separator':
		'The hashing algorithm salt separator field must be a valid byte buffer.',
	'auth/invalid-id-token': 'The provided ID token is not a valid Firebase ID token.',
	'auth/invalid-last-sign-in-time': 'The last sign-in time must be a valid UTC date string.',
	'auth/invalid-page-token':
		'The provided next page token in listUsers() is invalid. It must be a valid non-empty string.',
	'auth/invalid-password':
		'The provided value for the password user property is invalid. It must be a string with at least six characters.',
	'auth/invalid-password-hash': 'The password hash must be a valid byte buffer.',
	'auth/invalid-password-salt': 'The password salt must be a valid byte buffer',
	'auth/invalid-phone-number':
		'The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.',
	'auth/invalid-photo-url':
		'The provided value for the photoURL user property is invalid. It must be a string URL.',
	'auth/invalid-provider-data': 'The providerData must be a valid array of UserInfo objects.',
	'auth/invalid-provider-id':
		'The providerId must be a valid supported provider identifier string.',
	'auth/invalid-oauth-responsetype': 'Only exactly one OAuth responseType should be set to true.',
	'auth/invalid-session-cookie-duration':
		'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.',
	'auth/invalid-uid': 'The provided uid must be a non-empty string with at most 128 characters.',
	'auth/invalid-user-import': 'The user record to import is invalid.',
	'auth/maximum-user-count-exceeded':
		'The maximum allowed number of users to import has been exceeded.',
	'auth/missing-android-pkg-name':
		'An Android Package Name must be provided if the Android App is required to be installed.',
	'auth/missing-continue-uri': 'A valid continue URL must be provided in the request.',
	'auth/missing-hash-algorithm':
		'Importing users with password hashes requires that the hashing algorithm and its parameters be provided.',
	'auth/missing-ios-bundle-id': 'The request is missing an iOS Bundle ID.',
	'auth/missing-uid': 'A uid identifier is required for the current operation.',
	'auth/missing-oauth-client-secret':
		'The OAuth configuration client secret is required to enable OIDC code flow.',
	'auth/operation-not-allowed':
		'The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.',
	'auth/phone-number-already-exists':
		'The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.',
	'auth/project-not-found':
		'No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.',
	'auth/reserved-claims':
		'One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.',
	'auth/session-cookie-expired': 'The provided Firebase session cookie is expired.',
	'auth/session-cookie-revoked': 'The Firebase session cookie has been revoked.',
	'auth/uid-already-exists':
		'The provided uid is already in use by an existing user. Each user must have a unique uid.',
	'auth/unauthorized-continue-uri':
		'The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.',
	'auth/user-not-found':
		'There is no existing user record corresponding to the provided identifier.',
	'auth/wrong-password': 'The password is incorrect!'
}

export const DEFAULT_DRAG_ACTIONS = ['moved', 'added', 'removed']

export const DEFAULT_PRODUCT_CARD = {
	category: 'Finance',
	name: 'Fundamentals of Investment Banking',
	short_description:
		'Explores the introductory areas of investment banking in the modern day and age',
	lesson_count: 10,
	instructor: {
		name: 'Chan Doe',
		position: 'Venture Capitalist',
		avatar: 'https://tuk-cdn.s3.amazonaws.com/assets/components/avatars/a_4_4.png'
	}
}

export const DEFAULT_CATEGORIES = [
	{
		name: 'Category 1',
		value: 'Category 1',
		children: [
			{ name: 'Sub-Category 1', value: 'Category-1/Sub-Category 1' },
			{ name: 'Sub-Category 2', value: 'Category-1/Sub-Category 2' },
			{ name: 'Sub-Category 3', value: 'Category-1/Sub-Category 3' }
		]
	},
	{
		name: 'Category 2',
		value: 'Category 2',
		children: [
			{ name: 'Sub-Category 1', value: 'Category-2/Sub-Category 1' },
			{ name: 'Sub-Category 2', value: 'Category-2/Sub-Category 2' }
		]
	},
	{
		name: 'Category 3',
		value: 'Category 3',
		children: [
			{ name: 'Sub-Category 1', value: 'Category-3/Sub-Category 1' },
			{ name: 'Sub-Category 2', value: 'Category-3/Sub-Category 2' },
			{ name: 'Sub-Category 3', value: 'Category-3/Sub-Category 3' },
			{ name: 'Sub-Category 4', value: 'Category-3/Sub-Category 4' }
		]
	},
	{
		name: 'Category 4',
		value: 'Category 4',
		children: [{ name: 'Sub-Category 1', value: 'Category-4/Sub-Category 1' }]
	}
]

export const dayOfWeek = [
	{ name: 'Monday', value: 'Monday' },
	{ name: 'Tuesday', value: 'Tuesday' },
	{ name: 'Wednesday', value: 'Wednesday' },
	{ name: 'Thursday', value: 'Thursday' },
	{ name: 'Friday', value: 'Friday' },
	{ name: 'Saturday', value: 'Saturday' },
	{ name: 'Sunday', value: 'Sunday' },
	{ name: 'Mon-Fri', value: 'Mon - Fri' }
]

export const timeOfDay = [
	{
		name: '12:00 AM',
		value: '12:00 AM'
	},
	{
		name: '12:30 AM',
		value: '12:30 AM'
	},
	{
		name: '1:00 AM',
		value: '1:00 AM'
	},
	{
		name: '1:30 AM',
		value: '1:30 AM'
	},
	{
		name: '2:00 AM',
		value: '2:00 AM'
	},
	{
		name: '2:30 AM',
		value: '2:30 AM'
	},
	{
		name: '3:00 AM',
		value: '3:00 AM'
	},
	{
		name: '3:30 AM',
		value: '3:30 AM'
	},
	{
		name: '4:00 AM',
		value: '4:00 AM'
	},
	{
		name: '4:30 AM',
		value: '4:30 AM'
	},
	{
		name: '5:00 AM',
		value: '5:00 AM'
	},
	{
		name: '5:30 AM',
		value: '5:30 AM'
	},
	{
		name: '6:00 AM',
		value: '6:00 AM'
	},
	{
		name: '6:30 AM',
		value: '6:30 AM'
	},
	{
		name: '7:00 AM',
		value: '7:00 AM'
	},
	{
		name: '7:30 AM',
		value: '7:30 AM'
	},
	{
		name: '8:00 AM',
		value: '8:00 AM'
	},
	{
		name: '8:30 AM',
		value: '8:30 AM'
	},
	{
		name: '9:00 AM',
		value: '9:00 AM'
	},
	{
		name: '9:30 AM',
		value: '9:30 AM'
	},
	{
		name: '10:00 AM',
		value: '10:00 AM'
	},
	{
		name: '10:30 AM',
		value: '10:30 AM'
	},
	{
		name: '11:00 AM',
		value: '11:00 AM'
	},
	{
		name: '11:30 AM',
		value: '11:30 AM'
	},
	{
		name: '12:00 PM',
		value: '12:00 PM'
	},
	{
		name: '12:30 PM',
		value: '12:30 PM'
	},
	{
		name: '1:00 PM',
		value: '1:00 PM'
	},
	{
		name: '1:30 PM',
		value: '1:30 PM'
	},
	{
		name: '2:00 PM',
		value: '2:00 PM'
	},
	{
		name: '2:30 PM',
		value: '2:30 PM'
	},
	{
		name: '3:00 PM',
		value: '3:00 PM'
	},
	{
		name: '3:30 PM',
		value: '3:30 PM'
	},
	{
		name: '4:00 PM',
		value: '4:00 PM'
	},
	{
		name: '4:30 PM',
		value: '4:30 PM'
	},
	{
		name: '5:00 PM',
		value: '5:00 PM'
	},
	{
		name: '5:30 PM',
		value: '5:30 PM'
	},
	{
		name: '6:00 PM',
		value: '6:00 PM'
	},
	{
		name: '6:30 PM',
		value: '6:30 PM'
	},
	{
		name: '7:00 PM',
		value: '7:00 PM'
	},
	{
		name: '7:30 PM',
		value: '7:30 PM'
	},
	{
		name: '8:00 PM',
		value: '8:00 PM'
	},
	{
		name: '8:30 PM',
		value: '8:30 PM'
	},
	{
		name: '9:00 PM',
		value: '9:00 PM'
	},
	{
		name: '9:30 PM',
		value: '9:30 PM'
	},
	{
		name: '10:00 PM',
		value: '10:00 PM'
	},
	{
		name: '10:30 PM',
		value: '10:30 PM'
	},
	{
		name: '11:00 PM',
		value: '11:00 PM'
	},
	{
		name: '11:30 PM',
		value: '11:30 PM'
	}
]

export const timeOfDayB = [
	{
		name: '08:00',
		value: '08:00'
	},
	{
		name: '08:30',
		value: '08:30'
	},
	{
		name: '09:00',
		value: '09:00'
	},
	{
		name: '09:30',
		value: '09:30'
	},
	{
		name: '10:00',
		value: '10:00'
	},
	{
		name: '10:30',
		value: '10:30'
	},
	{
		name: '11:00',
		value: '11:00'
	},
	{
		name: '11:30',
		value: '11:30'
	},

	{
		name: '12:00',
		value: '12:00'
	},
	{
		name: '12:30',
		value: '12:30'
	},
	{
		name: '13:00',
		value: '13:00'
	},
	{
		name: '13:30',
		value: '13:30'
	},
	{
		name: '14:00',
		value: '14:00'
	},
	{
		name: '14:30',
		value: '14:30'
	},
	{
		name: '15:00',
		value: '15:00'
	},
	{
		name: '15:30',
		value: '15:30'
	},
	{
		name: '16:00',
		value: '16:00'
	},
	{
		name: '16:30',
		value: '16:30'
	},
	{
		name: '17:00',
		value: '17:00'
	},
	{
		name: '17:30',
		value: '17:30'
	},
	{
		name: '18:00',
		value: '18:00'
	},
	{
		name: '18:30',
		value: '18:30'
	},
	{
		name: '19:00',
		value: '19:00'
	},
	{
		name: '19:30',
		value: '19:30'
	},
	{
		name: '20:00',
		value: '20:00'
	}
]

export const regions = [
	{
		name: 'Argentina',
		value: 'AR'
	},
	{
		name: 'Aruba',
		value: 'AW'
	},
	{
		name: 'Australia',
		value: 'AU'
	},
	{
		name: 'Austria',
		value: 'AT'
	},
	{
		name: 'Bahamas',
		value: 'BS'
	},
	{
		name: 'Barbados',
		value: 'BB'
	},
	{
		name: 'Belgium',
		value: 'BE'
	},
	{
		name: 'Brazil',
		value: 'BR'
	},
	{
		name: 'Canada',
		value: 'CA'
	},
	{
		name: 'China',
		value: 'CN'
	},
	{
		name: 'Costa Rica',
		value: 'CR'
	},
	{
		name: 'Cuba',
		value: 'CU'
	},
	{
		name: 'Cyprus',
		value: 'CY'
	},
	{
		name: 'Denmark',
		value: 'DK'
	},
	{
		name: 'Fiji',
		value: 'FJ'
	},
	{
		name: 'Finland',
		value: 'FI'
	},
	{
		name: 'France',
		value: 'FR'
	},
	{
		name: 'Germany',
		value: 'DE'
	},
	{
		name: 'Gibraltar',
		value: 'GI'
	},
	{
		name: 'Guernsey',
		value: 'GG'
	},
	{
		name: 'Hong Kong',
		value: 'HK'
	},
	{
		name: 'India',
		value: 'IN'
	},
	{
		name: 'Indonesia',
		value: 'ID'
	},
	{
		name: 'Ireland',
		value: 'IE'
	},
	{
		name: 'Isle of Man',
		value: 'IM'
	},
	{
		name: 'Italy',
		value: 'IT'
	},
	{
		name: 'Jamaica',
		value: 'JM'
	},
	{
		name: 'Japan',
		value: 'JP'
	},
	{
		name: 'Lithuania',
		value: 'LT'
	},
	{
		name: 'Luxembourg',
		value: 'LU'
	},
	{
		name: 'Malaysia',
		value: 'MY'
	},
	{
		name: 'Malta',
		value: 'MT'
	},
	{
		name: 'Mexico',
		value: 'MX'
	},
	{
		name: 'Morocco',
		value: 'MA'
	},
	{
		name: 'Netherlands',
		value: 'NL'
	},
	{
		name: 'New Zealand',
		value: 'NZ'
	},
	{
		name: 'Norway',
		value: 'NO'
	},
	{
		name: 'Panama',
		value: 'PA'
	},
	{
		name: 'Paraguay',
		value: 'PY'
	},
	{
		name: 'Peru',
		value: 'PE'
	},
	{
		name: 'Philippines',
		value: 'PH'
	},
	{
		name: 'Poland',
		value: 'PL'
	},
	{
		name: 'Portugal',
		value: 'PT'
	},
	{
		name: 'Seychelles',
		value: 'SC'
	},
	{
		name: 'Singapore',
		value: 'SG'
	},
	{
		name: 'South Africa',
		value: 'ZA'
	},
	{
		name: 'Spain',
		value: 'ES'
	},
	{
		name: 'Sweden',
		value: 'SE'
	},
	{
		name: 'Switzerland',
		value: 'CH'
	},
	{
		name: 'Taiwan',
		value: 'TW'
	},
	{
		name: 'Thailand',
		value: 'TH'
	},
	{
		name: 'Turkey',
		value: 'TR'
	},
	{
		name: 'United Arab Emirates',
		value: 'AE'
	},
	{
		name: 'United Kingdom',
		value: 'GB'
	},
	{
		name: 'United States of America',
		value: 'US'
	}
]

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'ico', 'jfif']

export const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv']

export const videoExtensions = ['mp4', 'avi', 'mov', 'flv', 'wmv', 'mpg', 'mpeg', '3gp', 'mkv']

export const cloudflareImageExtensions = ['jpg', 'jpeg', 'png', 'jfif']

export const sideNavTabs = [
	{
		name: 'Settings',
		subTabsText: 'Settings Management',
		subTabs: [
			{
				name: 'Komunily Settings',
				tabIdentifier: 'TenantDetails',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" /></svg>`
			},
			{
				name: 'Domains',
				tabIdentifier: 'Domains',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" /></svg>`
			},
			{
				name: 'Branding',
				tabIdentifier: 'Branding',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" /></svg>`
			},
			{
				name: 'Categories',
				tabIdentifier: 'categories',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" /></svg>`
			},
			{
				name: 'Tags',
				tabIdentifier: 'tags',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" /></svg>`
			},
			{
				name: 'Meta',
				tabIdentifier: 'Meta',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>`
			},
			{
				name: 'Headers & Footers',
				tabIdentifier: 'Headers',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" /></svg>`
			},
			{
				name: 'Feedback',
				tabIdentifier: 'feedback',
				icon: `<svg fill="none"  class="h-6 w-6" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"></path>
</svg>`
			},
			{
				name: 'Redirects',
				tabIdentifier: 'redirects',
				icon: `<svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path>
</svg>`
			},
			{
				name: 'Advanced',
				tabIdentifier: 'Advanced',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" /></svg>`
			},
			{
				name: 'Integrations',
				tabIdentifier: 'Integrations',
				icon: `<svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"></path>
              </svg>`
			}
			// {
			// 	name: 'Views',
			// 	tabIdentifier: 'views',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" /></svg>`
			// }
		]
	},
	{
		name: 'Profile',
		subTabsText: 'Profile Management',
		subTabs: [
			{
				name: 'Edit Profile',
				tabIdentifier: 'profile',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" /></svg>`
			},
			{
				name: 'Tasks',
				tabIdentifier: 'tasks',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>`
			},
			{
				name: 'Company Details',
				tabIdentifier: 'company',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2" data-v-57880f2c=""><path stroke-linecap="round" stroke-linejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" data-v-57880f2c=""></path></svg>`
			},
			{
				name: 'Company Users & Licenses',
				tabIdentifier: 'company-users',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"  data-v-57880f2c=""><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" data-v-57880f2c=""></path></svg>`
			}
		]
	},
	{
		name: 'Forms',
		tabs: [
			{
				name: 'Dashboard',
				href: '/admin/forms',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>`
			}
		],
		subTabsText: 'Forms Management',
		subTabs: []
	},
	{
		name: 'Events',
		tabs: [
			{
				name: 'Events',
				href: '/admin/events/list',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"></path>
</svg>`
			},
			// {
			// 	name: 'Users',
			// 	href: '/admin/events/users',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>`
			// },
			// {
			// 	name: 'Tasks',
			// 	href: '/admin/events/tasks',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" /></svg>`
			// },
			{
				name: 'Settings',
				href: '/admin/events/settings',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>`
			}
		],
		subTabsText: 'Events Management',
		subTabs: []
	},
	{
		name: 'Edit Event',
		tabs: [
			{
				name: 'Events',
				href: '/admin/events/list',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"></path>
</svg>`
			},
			// {
			// 	name: 'Users',
			// 	href: '/admin/events/users',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>`
			// },
			// {
			// 	name: 'Tasks',
			// 	href: '/admin/events/tasks',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" /></svg>`
			// },
			{
				name: 'Settings',
				href: '/admin/events/settings',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>`
			}
		],
		subTabsText: 'Event Management',
		subTabs: [
			{
				name: 'Event Dashboard',
				tabIdentifier: 'event-dashboard',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"></path>
</svg>`
			},
			{
				name: 'Event Schedule',
				tabIdentifier: 'event-schedule',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1"><path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>`
			},
			{
				name: 'Event Sessions',
				tabIdentifier: 'event-sessions',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
				<path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path>
			  </svg>`
			},
			{
				name: 'Event Partners',
				tabIdentifier: 'event-partners',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path>
</svg>`
			},

			{
				name: 'Event Speakers',
				tabIdentifier: 'event-speakers',
				icon: `<svg fill="none" class="h-6 w-6" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"></path>
</svg>`
			},
			{
				name: 'Event Attendees',
				tabIdentifier: 'event-attendees',
				icon: `<svg fill="none" class="h-6 w-6" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
				<path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>
			</svg>`
			},
			{
				name: 'Event Embed',
				tabIdentifier: 'event-embed',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"></path>
              </svg>`
			},
			{
				name: 'Event Emails',
				tabIdentifier: 'event-emails',
				icon: `<svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"></path>
              </svg>`
			},
			// {
			// 	name: 'Chat Manager',
			// 	tabIdentifier: 'chat-manager',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" /></svg>`
			// },
			// {
			// 	name: 'Live Manager',
			// 	tabIdentifier: 'live-manager',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>`
			// },
			// {
			// 	name: 'Event Links',
			// 	tabIdentifier: 'event-links',
			// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2"><path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" /></svg>`
			// },
			{
				name: 'Event Settings',
				tabIdentifier: 'event-settings',
				icon: `<svg fill="none" stroke="currentColor" stroke-width="1.2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"></path>
</svg>`
			}
		]
	}
]
