import Login from './Middlewares/Login'

const adminRoutes = [
	{
		path: '/admin/learning',
		name: 'Learning Admin',
		component: () => import('../view/admin/CoursesAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/recordings',
		name: 'Recordings Admin',
		component: () => import('../view/Admin/RecordingsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/resources',
		name: 'Resources',
		component: () => import('../view/Admin/ResourcesAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/users',
		name: 'Users',
		component: () => import('../view/Admin/UsersAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/settings',
		name: 'Settings',
		component: () => import('../view/Admin/SettingsAdmin.vue'),
		meta: {
			middleware: [Login]
		},
		children: [
			{
				path: 'advanced',
				name: 'Advanced',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsAdvanced.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'branding',
				name: 'Branding',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsBranding.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'categories',
				name: 'Categories',
				component: () =>
					import('../view/ViewComponents/Admin/GlobalSettings/SettingsCategories.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'domains',
				name: 'Domains',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsDomains.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'headers',
				name: 'Headers & Footers',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsHeaders.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'instructors',
				name: 'Instructors',
				component: () =>
					import('../view/ViewComponents/Admin/GlobalSettings/SettingsInstructors.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'integrations',
				name: 'Integrations',
				component: () =>
					import('../view/ViewComponents/Admin/GlobalSettings/SettingsIntegration.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'meta',
				name: 'Meta',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsMeta.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'redirects',
				name: 'Redirects',
				component: () =>
					import('../view/ViewComponents/Admin/GlobalSettings/SettingsRedirects.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'feedback',
				name: 'Feedback',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsFeedback.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'tags',
				name: 'Tags',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsTags.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'komunily-settings',
				name: 'Komunily Settings',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsTenant.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'tracking',
				name: 'Tracking',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsTracking.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'views',
				name: 'Views',
				component: () => import('../view/ViewComponents/Admin/GlobalSettings/SettingsViews.vue'),
				meta: {
					middleware: [Login]
				}
			}
		]
	},
	{
		path: '/admin/faqs',
		name: 'FAQs',
		component: () => import('../view/Admin/FaqAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/analytics',
		name: 'Analytics',
		component: () => import('../view/Admin/AnalyticsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/forms',
		name: 'Forms',
		component: () => import('../view/Admin/FormsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/pages',
		name: 'Pages',
		component: () => import('../view/Admin/PagesAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/images',
		name: 'Images',
		component: () => import('../view/Admin/ImagesAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/companies',
		name: 'Companies',
		component: () => import('../view/Admin/CompaniesAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/events/',
		name: 'Events',
		component: () => import('../view/Admin/EventsAdmin.vue'),
		meta: {
			middleware: [Login]
		},
		children: [
			{
				path: 'list',
				name: 'Events List',
				component: () => import('../view/Admin/Events/EventsList.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'settings',
				name: 'Events Settings',
				component: () => import('../view/Admin/Events/EventsSettings.vue'),
				meta: {
					middleware: [Login]
				}
			}
		]
	},
	// { not currently active/planned
	// 	path: '/admin/events/tasks',
	// 	name: 'Events Tasks',
	// 	component: () => import('../view/Admin/Events/Tasks.vue'),
	// 	meta: {
	// 		middleware: [Login]
	// 	}
	// },
	{
		path: '/admin/webinars',
		name: 'Webinars',
		component: () => import('../view/Admin/WebinarsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/access',
		name: 'Access',
		component: () => import('../view/Admin/AccessAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/assessments',
		name: 'Assessments',
		component: () => import('../view/Admin/AssessmentsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/notifications',
		name: 'Notifications',
		component: () => import('../view/Admin/NotificationsAdmin.vue'),
		meta: {
			middleware: [Login]
		}
	}
]

export default adminRoutes
