<template>
	<!-- <div class="flex relative right-0 z-10 items-center justify-start px-3 py-2 shadow-lg xl:hidden">
		<div
			v-on:click="toggleOpen"
			class="w-10 h-10 cursor-pointer rounded-sm hover:bg-gray-100 p-2 transition-all hidden xl-max:block"
		>
			<svg
				class="w-5 h-5 text-gray-600"
				fill="none"
				stroke="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="1.2"
					d="M4 6h16M4 12h16M4 18h16" />
			</svg>
		</div>
	</div> -->

	<div
		class="bg-white min-h-screen shadow block sm:table-cell align-top font-Poppins absolute h-full z-50">
		<div
			:class="{ hidden: toggle }"
			class="relative top-0 min-h-auto sm:min-h-screen bottom-0 py-4 flex items-start flex-col select-none lg:block border-r border-gray-200">
			<!-- <svg
				@click="toggleOpen"
				class="xl:hidden w-6 h-6 absolute top-2 right-2 cursor-pointer"
				fill="none"
				stroke="currentColor"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="1.2"
					d="M6 18L18 6M6 6l12 12"
				></path>
			</svg> -->

			<div class="flex flex-col grow-0 justify-between">
				<ul aria-orientation="vertical" class="rounded flex flex-col">
					<router-link
						:aria-label="'View ' + name"
						class="cursor-pointer group px-4 py-1"
						v-for="({ name, href, icon }, index) of tabs"
						:key="index"
						:to="{ path: `${href}` }">
						<div
							:class="[
								'flex items-center gap-4 group-hover:bg-blue-100 group-hover:text-blue-500 transition',
								menu ? 'rounded-full p-2' : 'rounded-md pl-2 pr-14 py-2'
							]">
							<div
								v-if="icon"
								v-html="icon"
								:class="[
									'w-6 h-6 text-gray-150 group-hover:text-blue-500',
									isActive(href) ? 'text-blue-500' : 'text-gray-150'
								]"></div>
							<span v-if="!menu" class="text-black font-light">{{ name }}</span>
						</div>
					</router-link>
					<div v-if="subTabs.length > 0" :class="['w-full py-2', !menu ? 'px-6' : 'px-4']">
						<span v-if="!menu" class="text-gray-250 text-sm">
							{{ subTabsText.toUpperCase() }}
						</span>
						<div v-if="!menu && tabs" class="bg-gray-150 mt-2" :style="`height: 1px`"></div>
					</div>
					<router-link
						:aria-label="'View ' + name"
						class="cursor-pointer group px-4 py-1"
						v-for="({ name, icon }, index) of subTabs"
						:key="index"
						:to="{ name }">
						<div
							:class="[
								'flex items-center gap-4 group-hover:bg-blue-100 group-hover:text-blue-500 transition',
								menu ? 'rounded-full p-2' : 'rounded-md pl-2 pr-18 py-2'
							]">
							<div
								v-if="icon"
								v-html="icon"
								:class="[
									'w-6 h-6 text-gray-150 group-hover:text-blue-500',
									$route.name === name ? 'text-blue-500' : 'text-gray-150'
								]"></div>
							<span v-if="!menu" class="text-black font-light">{{ name }}</span>
						</div>
					</router-link>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { sideNavTabs } from '@constants/Default'

const store = useStore()
const route = useRoute()

const toggle = ref(true)
const menu = ref(true)
const tabs = ref([])
const subTabsText = ref('')
const subTabs = ref([])

const getSideMenuOpen = computed(() => store.getters.getSideMenuOpen)

const setupTabs = () => {
	const { name } = route.matched[0]
	const routeTabs = sideNavTabs.find((tab) => tab.name === name)

	if (routeTabs && routeTabs?.tabs?.length > 0) {
		tabs.value = routeTabs.tabs
	} else {
		tabs.value = []
	}

	if (routeTabs && routeTabs?.subTabs?.length > 0) {
		subTabsText.value = routeTabs.subTabsText
		subTabs.value = routeTabs.subTabs
	} else {
		subTabs.value = []
	}
}

const isActive = (link) => {
	const { path } = route
	return link === path
}

watch(getSideMenuOpen, (val, oldVal) => {
	if (val !== oldVal) {
		menu.value = !val
	}
})

watch(
	() => route.path,
	() => {
		setupTabs()
	}
)

onMounted(() => {
	setupTabs()
})
</script>

<style scoped>
.sidebar {
	display: table-cell;
	vertical-align: top;
}
</style>
